<template>
  <div class='examine_detail' v-loading="loading">
    <back />

    <div class='content globle_border'>
      <el-form ref="form1" class="form" :model="form" label-position="left" label-width="170px" disabled>
        <div class="title1">商超信息</div>
        <el-form-item label="营业执照">
          <el-image class="busilicense" fit="cover" :src="form.businessLicenseUrl"
            :preview-src-list="[form.businessLicenseUrl]"></el-image>
        </el-form-item>
        <el-form-item label="商超名称">
          <el-input v-model="form.shopName"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.creditCode"></el-input>
        </el-form-item>
        <el-form-item label="关联学校">
          <el-input v-model="form.schoolName"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contactMan"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.contactTel"></el-input>
        </el-form-item>
        <el-form-item label="商超地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <!-- <el-input type="textarea" v-model="form.introduction" :autosize="{ minRows: 5, maxRows: 15 }"></el-input> -->
          <div v-html="form.introduction" class="Runecloth"></div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      id: null,
      loading: false,
      mapStatus: '',
      form: {

      },
    }
  },
  created() {
    this.id = this.$route.query.shopId
    this.getSupplierDetail()
  },
  methods: {
    getSupplierDetail() {
      this.loading = true
      this.$axios.get(`${this.$api.getMyTerminalShopInfo}/${this.id}`).then((res) => {
        this.form = res.data.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },

  },
}
</script>

<style scoped lang='scss'>
.examine_detail {
  .content {
    padding: 60px 190px;
  }

  .form {
    .red {
      ::v-deep .el-input__inner {
        color: #eb2500 !important;
      }
    }

    .gray {
      ::v-deep .el-input__inner {
        color: #68706e !important;
      }
    }

    .Runecloth {
      width: 1000px;
      min-height: 40px;
      border-radius: 10px;
      border: 1px solid #e5e7e6;
      padding: 0px 10px;

      ::v-deep img {
        object-fit: contain;
        width: 100%;
      }
    }

    .title1,
    .title2 {
      position: relative;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .title1::before,
    .title2::before {
      content: "";
      position: absolute;
      top: 0;
      left: -12px;
      width: 4px;
      height: 22px;
      background-color: #069bbc;
    }

    .title2 {
      margin-top: 60px;
    }

    ::v-deep .el-input {
      width: 650px;
    }

    ::v-deep .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 40px;
    }

    ::v-deep .el-textarea {
      width: 650px;
    }

    ::v-deep .el-input__inner,
    ::v-deep .el-textarea__inner {
      background: #fff;
      border-radius: 10px;
      border: 1px solid #e5e7e6;
    }

    ::v-deep .el-input.is-disabled .el-input__inner,
    ::v-deep .el-textarea.is-disabled .el-textarea__inner {
      color: #1a1a1a;
    }

    .logo {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 133px;
      height: 100px;
      border-radius: 4px;
    }
  }

  .times {
    padding: 30px;
    margin-top: 30px;
    box-sizing: border-box;

    .exinfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ::v-deep .el-form-item__label {
        padding-right: 16px;
      }

      ::v-deep .el-form-item {
        margin: 0;
      }
    }

    .lose_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .label {
        width: 90px;
        padding-right: 16px;
      }

      .label,
      ::v-deep .el-textarea__inner {
        color: #eb2500 !important;
      }

      ::v-deep .el-form-item__label {
        padding-right: 16px;
      }

      ::v-deep .el-form-item {
        display: block;
        margin: 0;
      }
    }

    .status {
      width: 202px;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #e5e7e6;
      border-radius: 10px;
      box-sizing: border-box;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #069bbc;
    }

    .gray {
      color: #68706e;
    }
  }

  .btn {
    text-align: center;
    margin: 30px 0;

    .el-button {
      width: 150px;
      margin: 0 20px;
    }
  }
}
</style>